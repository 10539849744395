'use client';

import CardButton from '~/core/ui/CardButton';
import { Building08 } from '@untitled-ui/icons-react';

function AdminButtonContainer() {
  return (
    <CardButton
      data-cy={'organization-card-button'}
      className={'relative'}
      href="/admin"
    >
      <span
        className={
          'absolute flex justify-center' +
          ' h-full w-full items-center space-x-4'
        }
      >
        <Building08 className={'h-5'} />

        <span className={'flex items-center space-x-2.5 text-base font-medium'}>
          <span>Admin</span>
        </span>
      </span>
    </CardButton>
  );
}

export default AdminButtonContainer;
