import classNames from 'clsx';
import Heading from '~/core/ui/Heading';
import Container from '~/core/ui/Container';
import type { PropsWithChildren, ReactNode } from 'react';
import { cn } from '~/core/utils/shadcn-utils';

export function Page(
  props: PropsWithChildren<{
    sidebar?: ReactNode;
    contentContainerClassName?: string;
    className?: string;
  }>
) {
  return (
    <div className={props.className ?? 'h-full w-full'}>
      <div className={'hidden lg:block'}>{props.sidebar}</div>

      <div
        className={
          props.contentContainerClassName ??
          'mx-auto flex flex-col h-screen w-full overflow-y-auto'
        }
      >
        {props.children}
      </div>
    </div>
  );
}

export function PageBody(
  props: PropsWithChildren<{
    className?: string;
    skipContainer?: boolean;
  }>
) {
  const className = classNames(
    'h-full w-full flex flex-col flex-1',
    props.className
  );

  return (
    <div className={className}>
      {props.skipContainer ? (
        props.children
      ) : (
        <Container>{props.children}</Container>
      )}
    </div>
  );
}

export function PageHeader({
  children,
  title,
  description,
  mobileNavigation,
  className,
}: PropsWithChildren<{
  title: string | ReactNode;
  description?: string | ReactNode;
  mobileNavigation?: ReactNode;
  className?: string;
}>) {
  return (
    <div
      className={cn('flex items-start justify-between p-container', className)}
    >
      <div
        className={
          'flex space-x-2 items-center lg:items-start lg:flex-col lg:space-x-0 lg:gap-[6px]'
        }
      >
        <div className={'flex items-center lg:hidden'}>{mobileNavigation}</div>

        <span className={'flex items-center space-x-0.5 lg:space-x-2'}>
          <span className={'font-semibold text-2xl text-sidebar-foreground'}>
            {title}
          </span>
        </span>

        <span className={'text-sub-foreground text-sm leading-normal'}>
          {description}
        </span>
      </div>

      {children}
    </div>
  );
}
