'use client';

import { getPath } from '~/core/routes/get-path';
import CardButton from '~/core/ui/CardButton';
import If from '~/core/ui/If';
import { PageBody, PageHeader } from '~/core/ui/Page';
import NewOrganizationButtonContainer from '~/app/(workspace)/components/NewOrganizationButtonContainer';
import AdminButtonContainer from '~/app/(workspace)/components/AdminButtonContainer';
import LogoImage from '~/core/ui/Logo/LogoImage';
import Trans from '~/core/ui/Trans';
import type { OrganizationDataOutput } from '@topo-io/client';
import { setCookie } from '~/core/utils/cookies';
import configuration from '~/configuration';

export default function OrganizationsSelectorPage({
  csrfToken,
  authUserId,
  organizations,
  superAdmin,
}: {
  csrfToken: string;
  authUserId: string;
  organizations: OrganizationDataOutput[];
  superAdmin: boolean;
}) {
  const handleOrganizationSelect = (organizationId: string) => {
    setCookie('organizationId', organizationId);
    window.location.href = getPath(configuration.paths.appHome);
  };

  return (
    <div className={'flex flex-col space-y-8'}>
      <OrganizationsPageHeader />
      <PageBody>
        <div
          className={
            'lg:grid-col-3 grid grid-cols-1 gap-4 xl:grid-cols-4 xl:gap-6'
          }
        >
          <NewOrganizationButtonContainer
            csrfToken={csrfToken}
            authUserId={authUserId}
          />

          {organizations.map((organization) => {
            return (
              <CardButton
                data-cy={'organization-card-button'}
                className={'relative'}
                onClick={() => {
                  handleOrganizationSelect(organization.id);
                }}
                key={organization.id}
              >
                <span
                  className={
                    'absolute flex justify-center' +
                    ' h-full w-full items-center space-x-4'
                  }
                >
                  <span
                    className={
                      'flex items-center space-x-2.5 text-base font-medium'
                    }
                  >
                    <span>{organization.name}</span>
                  </span>
                </span>
              </CardButton>
            );
          })}

          <If condition={superAdmin}>
            <AdminButtonContainer />
          </If>
        </div>
      </PageBody>
    </div>
  );
}

function OrganizationsPageHeader() {
  return (
    <PageHeader
      title={
        <div className={'flex space-x-4 items-center'}>
          <LogoImage />

          <span>
            <Trans i18nKey={'common:yourOrganizations'} />
          </span>
        </div>
      }
    />
  );
}
