import classNames from 'clsx';
import { cva } from 'cva';

type Color = `normal` | 'success' | 'warn' | 'error' | 'info' | 'custom';
type Size = `normal` | `small` | 'custom';

const classNameBuilder = getClassNameBuilder();

const Badge: React.FCC<{
  color?: Color;
  size?: Size;
  onClick?: () => void;
  className?: string;
}> = ({ children, color, size, onClick, className, ...props }) => {
  const baseClassName = classNameBuilder({
    color,
    size,
  });

  return (
    <div
      className={classNames(baseClassName, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
};

function getClassNameBuilder() {
  return cva(['flex space-x-2 items-center font-medium'], {
    variants: {
      color: {
        normal: 'text-foreground bg-secondary',
        success: 'bg-reply-background text-positive',
        warn: 'bg-yellow-50 dark:bg-yellow-100/10 text-yellow-800',
        error: 'bg-destructive-foreground text-destructive',
        info: 'bg-sky-50 dark:bg-sky-500/10 text-sky-800 dark:text-sky-600',
        custom: '',
      },
      size: {
        normal: 'rounded-lg px-3 py-2 text-sm',
        small: 'rounded px-2 py-1 text-xs',
        custom: '',
      },
    },
    defaultVariants: {
      color: 'normal',
      size: 'normal',
    },
  });
}

export default Badge;
