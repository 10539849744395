import classNames from 'clsx';
import { forwardRef } from 'react';
import Button from '~/core/ui/Button';

const CardButton = forwardRef<
  typeof Button,
  React.ComponentProps<typeof Button>
>(function CardButtonComponent(props, _) {
  return (
    <Button
      {...props}
      size={'large'}
      variant={'custom'}
      className={classNames(
        props.className,
        'h-28 cursor-pointer rounded-sm ring-primary border transition-all active:bg-gray-50 dark:ring-primary/70 dark:active:bg-background/80'
      )}
    >
      {props.children}
    </Button>
  );
});

export default CardButton;
