'use client';

import { useState } from 'react';

import CardButton from '~/core/ui/CardButton';
import Trans from '~/core/ui/Trans';

import CreateOrganizationModal from '~/app/(workspace)/components/organizations/CreateOrganizationModal';
import CsrfTokenContext from '~/components/contexts/csrf';
import { Plus } from '@untitled-ui/icons-react';

function NewOrganizationButtonContainer({
  csrfToken,
  authUserId,
}: React.PropsWithChildren<{
  csrfToken: string;
  authUserId: string;
}>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <CsrfTokenContext.Provider value={csrfToken}>
      <CardButton onClick={() => setIsModalOpen(true)}>
        <span className={'flex items-center space-x-4'}>
          <Plus className={'h-6 w-6'} />

          <span className={'text-base font-medium'}>
            <Trans i18nKey={'organization:createOrganizationDropdownLabel'} />
          </span>
        </span>
      </CardButton>

      <CreateOrganizationModal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        authUserId={authUserId}
      />
    </CsrfTokenContext.Provider>
  );
}

export default NewOrganizationButtonContainer;
